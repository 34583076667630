import React, { forwardRef, ReactNode, ForwardRefExoticComponent, RefAttributes } from 'react';
import styled, { css } from 'styled-components';
import { BaseCard, CardProps, CardVariant } from './BaseCard';
import { radius, spacing, themeLight } from '@naf/theme';
import { Text, TextProps, TextVariant } from '@naf/text';
import { Label, LabelProps } from '@naf/label';

export interface CardListProps extends Omit<CardProps, 'title' | 'variant'> {
  /**
   * Customized to send in images from Cloudinary using `AdvancedImage`.
   */
  advancedImage?: ReactNode;

  /**
   * Set the image source (either URL or local file). Should also contain alt-text for the image.
   */
  img?: {
    src: string;
    alt: string;
  };

  /**
   * Title of the card.
   */
  title: string;

  /**
   * Set the HTML-tag for the title of the card.
   */
  headerTag?: TextProps['tag'];

  /**
   * Set a `Label`-component at the top of the card.
   */
  label?: LabelProps;

  /**
   * Set the size and style of the card.
   */
  variant?: CardVariant.Small | CardVariant.Standard;
}

export const CardList: ForwardRefExoticComponent<CardListProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  CardListProps
>(
  (
    {
      advancedImage,
      img,
      title,
      headerTag = 'h3',
      label,
      width,
      variant = CardVariant.Standard,
      children,
      ...rest
    }: CardListProps,
    ref,
  ) => {
    return (
      <ListCard variant={variant} ref={ref} {...rest}>
        {(img || advancedImage) && (
          <ImgWrap>
            {advancedImage && advancedImage}
            {img && !advancedImage && <img src={img.src} alt={img.alt} />}
          </ImgWrap>
        )}
        <Content>
          {label && <CardLabel {...label} />}
          {title && (
            <Header
              tag={headerTag}
              variant={variant === 'small' ? TextVariant.ArticleTextHeader : TextVariant.CardHeader}
            >
              <HoverWrap>{title}</HoverWrap>
            </Header>
          )}
          <Body>{children}</Body>
        </Content>
      </ListCard>
    );
  },
);

const HoverWrap = styled.span`
  background-size: 0 2px;
  background-position: 0 100%;
  background-image: linear-gradient(
    90deg,
    ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.interactiveElement.active
        : themeLight.componentColors.interactiveElement.active},
    ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.interactiveElement.active
        : themeLight.componentColors.interactiveElement.active}
  );
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
  text-decoration: none;
  display: inline;
`;

const ListCard = styled(BaseCard)`
  ${({ isNotClickable }) =>
    isNotClickable
      ? null
      : css`
          &:hover {
            img {
              transform: scale(1.02);
            }
            ${HoverWrap} {
              background-size: 100% 2px;
            }
          }
        `}
`;

const ImgWrap = styled.figure`
  margin: 0;
  overflow: hidden;
  flex: none;
  border-radius: ${radius.s};

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.6s ease;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
`;

const CardLabel = styled(Label)`
  margin-bottom: ${spacing.space8};
  margin-right: 100%;
  white-space: nowrap;
`;

const Header = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space8};
`;

const Body = styled.span`
  flex-basis: 100%;
`;
