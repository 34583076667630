import React, { useState, useEffect, ReactNode, FC } from 'react';
import styled, { css } from 'styled-components';
import { spacing, icons, themeLight } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Close } from '@styled-icons/material/Close';
import { Check } from '@styled-icons/material/Check';
import { Text, TextVariant } from '@naf/text';

type SizeType = 'small' | 'standard';
type VariantType = 'signature' | 'moss' | 'outline' | 'signature40';
type IconType = 'close' | 'checked' | ReactNode;

export interface ChipsProps {
  /**
   * Set the height and spacing of the chip.
   */
  size?: SizeType;

  /**
   * Set the appearance of the chip. Gets colors from NAF's design guide.
   */
  variant?: VariantType;

  /**
   * Set an icon on the chip. Used to illustrate a state or action.
   */
  icon?: IconType;

  /**
   * Set the text on the chip.
   */
  text: string;

  /**
   * Set to make the chip inactive. Overrides the styling from `variant`.
   */
  disabled?: boolean;

  /**
   * Set aria-label to be read by screen readers if other visible label is not available.
   */
  ariaLabel?: string;

  /**
   * Function when the chip is clicked.
   */
  onClick: () => void;

  [rest: string]: any;
  className?: string;
}

const renderIcon = (icon: string | ReactNode) => {
  switch (icon) {
    case 'close':
      return <Close size={16} />;
    case 'checked':
      return <Check size={16} />;
    default:
      return icon;
  }
};

export const Chips: FC<ChipsProps> = ({
  size = 'standard',
  variant = 'outline',
  icon,
  text,
  disabled,
  ariaLabel,
  onClick,
  className,
  ...rest
}: ChipsProps) => {
  const [mouseDown, setMouseDown] = useState(false);

  const removeOutline = () => {
    setMouseDown(true);
  };

  const addOutline = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      setMouseDown(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', removeOutline);

    return () => window.removeEventListener('mousedown', removeOutline);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', addOutline);

    return () => window.removeEventListener('keydown', addOutline);
  }, []);

  const chipVariant = (size: string) => {
    switch (size) {
      case 'small':
        return TextVariant.Small;
      case 'standard':
        return TextVariant.BodyText;
      default:
        return TextVariant.BodyText;
    }
  };

  return (
    <ClickableArea className={className} variant={variant} disabled={disabled} onClick={disabled ? undefined : onClick}>
      <Chip
        size={size}
        variant={variant}
        icon={icon}
        aria-label={ariaLabel}
        id={mouseDown ? 'mousedown' : ''}
        disabled={disabled}
        {...rest}
      >
        <StyledText variant={chipVariant(size)}>{text}</StyledText>
        {icon && renderIcon(icon)}
      </Chip>
    </ClickableArea>
  );
};

export default Chips;

const Chip = styled.div<{
  size: SizeType;
  variant?: VariantType;
  icon?: IconType;
  disabled?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin: ${spacing.space4};
  border: 1px solid transparent;
  box-sizing: border-box;
  position: relative;
  color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      background: 'transparent';
      border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
    `}

  ${({ variant }) =>
    variant === 'signature' &&
    css`
      background: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.interactiveElement.active
          : themeLight.componentColors.interactiveElement.active};
    `}

  ${({ variant }) =>
    variant === 'moss' &&
    css`
      background: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.cta.secondary : themeLight.componentColors.cta.secondary};
    `}

  ${({ variant }) =>
    variant === 'signature40' &&
    css`
      background: ${nafColor.signature.yellow40};
    `}

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
      border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
    `}

  /* small */
  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 0 ${spacing.space8};
      height: ${spacing.space24};
    `}

    /* standard */
  ${({ size }) =>
    size === 'standard' &&
    css`
      padding: 0 ${spacing.space16};
      height: ${spacing.space32};
    `}
  appearance: none;

  &:hover {
    cursor: pointer;
  }

  ${({ icon }) =>
    icon === 'close' &&
    css`
      svg {
        order: 1;
        padding-left: ${spacing.space8};
        font-size: ${icons.s};
      }
    `}

  ${({ icon }) =>
    icon === 'checked' &&
    css`
      svg {
        order: -1;
        padding-right: ${spacing.space8};
        font-size: ${icons.s};
      }
    `}

  ${({ icon }) =>
    icon &&
    icon !== 'checked' &&
    icon !== 'close' &&
    css`
      svg {
        order: 1;
        padding-left: ${spacing.space8};
        font-size: ${icons.s};
      }
    `}

  ${({ icon, size }) =>
    icon === 'close' &&
    size === 'small' &&
    css`
      svg {
        padding-left: ${spacing.space4};
        font-size: ${spacing.space12};
      }
    `}

  ${({ icon, size }) =>
    icon === 'checked' &&
    size === 'small' &&
    css`
      svg {
        padding-right: ${spacing.space4};
        font-size: ${spacing.space12};
      }
    `}

  ${({ icon, size }) =>
    icon &&
    size === 'small' &&
    css`
      svg {
        padding-left: ${spacing.space4};
        font-size: ${spacing.space12};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.interactiveElement.disabled
          : themeLight.componentColors.interactiveElement.disabled};
      color: ${nafColor.neutral.neutral5};
      border: 1px solid transparent;
      cursor: not-allowed;

      &:hover {
        cursor: not-allowed;
        border: 1px solid transparent;
      }
    `}

  &#mousedown {
    outline: 0;
  }
`;

const StyledText = styled(Text)`
  margin: 0;
`;

const ClickableArea = styled.button<{
  variant?: VariantType;
  disabled?: boolean;
}>`
  /* reset style */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;
  touch-action: manipulation;

  /* style */
  min-height: ${spacing.space40};
  min-width: ${spacing.space40};
  height: fit-content;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: initial;
    `}

  &:focus, &:focus-visible {
    outline: 0 !important;
    ${Chip} {
      outline: 1px solid
        ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};
    }
  }

  &:hover {
    ${Chip} {
      ${({ variant }) =>
        variant === 'outline' &&
        css`
          border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        `}

      ${({ variant }) =>
        variant === 'signature' &&
        css`
          border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
        `}

      ${({ variant }) =>
        variant === 'signature40' &&
        css`
          border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
        `}

      ${({ variant }) =>
        variant === 'moss' &&
        css`
          border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
        `}
    }
  }
`;
