import React, { FC, createContext, useContext, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, fontStyle, spacing, themeLight } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

//TextList

type TextListVariant = 'bullet' | 'number' | 'check' | 'close'
const TextListContext = createContext<TextListVariant>('bullet');

export interface TextListProps {
  /**
   * List content.
   */
  children?: ReactNode;

  /**
   * Formatting of list items.
   */
  variant?: TextListVariant;

  /**
   * Optional classname.
   */
  className?: string;

  /**
   * Set max-width on list container in pixels.
   */
  maxWidth?: number;
}

export const TextList: FC<TextListProps> = ({ children, variant = 'bullet', className, maxWidth }) => {
  const tag = variant === 'number' ? 'ol' : 'ul';

  return (
    <TextListContext.Provider value={variant}>
      <List as={tag} className={className} maxWidth={maxWidth}>
        {children}
      </List>
    </TextListContext.Provider>
  );
};


const renderIcon = (variant: TextListVariant) => {
  switch (variant) {
    case 'close':
      return <CloseIcon />;
    case 'check':
      return <CheckIcon />;
    case 'bullet':
    case 'number':
    default:
      return null;
  }
}

// TextListItem
export interface TextListItemProps {
  children: ReactNode;
  className?: string;
}
export const TextListItem: FC<TextListItemProps> = ({ children, className }) => {
  const variant = useContext(TextListContext);

  return (
    <Item variant={variant} className={className}>
      {renderIcon(variant)}
      {children}
    </Item>
  );
};

// Style
const List = styled.ul<{ maxWidth: number | undefined }>`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding-left: ${spacing.space24};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
`;

const Item = styled.li<{ variant: string }>`
  position: relative;
  box-sizing: border-box;
  ${fontStyle.article.articleText};
  padding-left: ${spacing.space12};
  margin-bottom: ${spacing.space8};
  list-style: none;

  ${({ variant }) =>
    variant === 'bullet' &&
    css`
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.extras.accent : themeLight.componentColors.extras.accent};
        position: absolute;
        top: 11px; /* Visually align center  */
        left: calc(-${spacing.space16} - 1px); /* -1px to visually align center  */
      }
    `}

  ${({ variant }) =>
    variant === 'number' &&
    css`
      list-style: decimal;
    `}

  &:last-child,
    &:only-child {
    margin-bottom: 0;
  }
`;

const CheckIcon = () => {
  return (
    <CheckIconWrapper>
      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.18305 0.307678L9.30679 1.61085L4.07014 7.69229L0.691406 3.78279L1.81515 2.47962L4.07014 5.08596L8.18305 0.307678Z"
          fill="#062E2A"
        />
      </svg>
    </CheckIconWrapper>
  );
};

const CloseIcon = () => {
  return (
    <CloseIconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.6666 4.27331L11.7266 3.33331L7.99998 7.05998L4.27331 3.33331L3.33331 4.27331L7.05998 7.99998L3.33331 11.7266L4.27331 12.6666L7.99998 8.93998L11.7266 12.6666L12.6666 11.7266L8.93998 7.99998L12.6666 4.27331Z" fill="#1A1A1A" />
      </svg>
    </CloseIconWrapper>
  );
};

const IconWrapper = styled.div`
  border-radius: 50%;
  position: absolute;
  width: ${spacing.space16};
  height: ${spacing.space16};
  top: 7px; /* Visually align center  */
  left: calc(-${spacing.space16} - 5px); /* -5px to visually align center  */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  box-sizing: border-box;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const CheckIconWrapper = styled(IconWrapper)`
  background:  ${nafColor.primary.park};

  path {
    fill: ${nafColor.neutral.neutral1};
  }
`

const CloseIconWrapper = styled(IconWrapper)`
  background:  ${nafColor.neutral.neutral1};

  path {
    fill: ${nafColor.signature.black};
  }

  @media (max-width: ${breakpoints.s}) {
    background:  ${nafColor.signature.white};

    path {
      fill: ${nafColor.neutral.neutral4};
    }
  }

`