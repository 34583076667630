import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing, radius, themeLight } from '@naf/theme';
import { Commute } from '@styled-icons/material/Commute';
import { DriveEta } from '@styled-icons/material/DriveEta';
import { LocalGasStation } from '@styled-icons/material/LocalGasStation';

/*
TODO:
    - Add correct icons
    - Add functionality for placement on images
*/

type SizeType = 'default' | 'small';
type IconType = 'commute' | 'driveEta' | 'localGasStation';
type VariantType = 'white' | 'moss' | 'spruce' | 'signature';

export interface LabelProps {
  /**
   * Tekst på labelen.
   */
  text: string;
  className?: string;
  /**
   * Labelens størrelse. Setter høyde, spacing og fontstørrelse.
   */
  size?: SizeType;
  /**
   * Setter ikon på labelen.
   */
  icon?: IconType;
  /**
   * Send inn et eget ikon fra Material-ui: https://material-ui.com/components/material-icons/
   */
  customIcon?: React.ReactNode;
  /**
   * Labelens utseende. Henter farger fra NAF's designguide.
   */
  variant?: VariantType;
}

const renderIcon = (icon: string) => {
  switch (icon) {
    case 'commute':
      return <Commute size={16} />;
    case 'driveEta':
      return <DriveEta size={16} />;
    case 'localGasStation':
      return <LocalGasStation size={16} />;
    default:
      return null;
  }
};

export const Label: FC<LabelProps> = ({
  text,
  className,
  size = 'default',
  icon,
  customIcon,
  variant = 'white',
}: LabelProps) => {
  return (
    <Tag size={size} variant={variant} className={className}>
      {icon ? renderIcon(icon) : null}
      {customIcon}
      {text}
    </Tag>
  );
};

export default Label;

const Tag = styled.span<{ size: SizeType; variant: VariantType }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radius.s};

  svg {
    margin-right: ${spacing.space8};
  }

  /* Default */
  ${({ size }) =>
    size === 'default' &&
    css`
      padding: 0 ${spacing.space8} 0 ${spacing.space8};
      height: ${spacing.space24};
      ${fontStyle.componentText.tag}
    `}

  /* Small */
    ${({ size }) =>
    size === 'small' &&
    css`
      padding: 0 ${spacing.space4} 0 ${spacing.space4};
      height: ${spacing.space20};
      ${fontStyle.componentText.tagSmall}
    `}

    /* White */
    ${({ variant }) =>
    variant === 'white' &&
    css`
      background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
      color: ${({ theme }) => (theme.typography ? theme.typography.brandText : themeLight.typography.brandText)};
    `}

    /* Moss */
    ${({ variant }) =>
    variant === 'moss' &&
    css`
      background: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.cta.secondary : themeLight.componentColors.cta.secondary};
      color: ${({ theme }) => (theme.typography ? theme.typography.brandText : themeLight.typography.brandText)};
    `}

    /* Spruce */
    ${({ variant }) =>
    variant === 'spruce' &&
    css`
      background: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.cta.secondaryInverted
          : themeLight.componentColors.cta.secondaryInverted};
      color: ${({ theme }) =>
        theme.typography ? theme.typography.brandTextInverted : themeLight.typography.brandTextInverted};
    `}

    /* Signature */
    ${({ variant }) =>
    variant === 'signature' &&
    css`
      background: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.cta.primary : themeLight.componentColors.cta.primary};
      color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};
    `}
`;
