import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  ReactNode,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributeAnchorTarget,
  RefObject,
  MouseEvent,
} from 'react';
import styled, { css } from 'styled-components';
import { spacing, radius, themeLight, icons } from '@naf/theme';
import { Loader } from '@naf/loader';
import { Text, TextVariant } from '@naf/text';

export type ButtonVariant =
  | 'signature'
  | 'outline'
  | 'spruce'
  | 'secondary'
  | 'outline-inverted'
  | 'danger';

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonWidth = 'content' | 'half' | 'full';

export interface ButtonBaseProps {
  /**
   * Change the appearance of the button, fetching colors from NAF's designguide.
   */
  variant?: ButtonVariant;

  /**
   * Change the size of the button.
   */
  size?: ButtonSize;

  /**
   * Change the width of the button, relative to the parent container. If not set, the width will be adjusted to the button's text.
   */
  width?: ButtonWidth;

  /**
   * Set the button to disabled. Overrides styling from `variant`.
   */
  disabled?: boolean;

  /**
   * Set to indicate that the button's function is loading content or an action. Overrides styling from `variant`.
   */
  isLoading?: boolean;

  /**
   * Set to customize text shown when loading content.
   */
  loadingLabel?: string;

  /**
   * Set to true to render a placeholder div, so that you can wrap the button in other clickable elements, needed to modify the button to handle internal links in nafno.
   */
  isNotClickable?: boolean;
}

export interface ButtonAnchorElementProps
  extends ButtonBaseProps,
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'href'> {
  /**
   * Set to use the button as a link. The button will then be rendered in `<a>`-tags.
   */
  href: string;

  /**
   * Can be used with the `href`-prop to specify where the link opens.
   */
  target?: HTMLAttributeAnchorTarget;
}

export interface ButtonButtonElementProps
  extends ButtonBaseProps,
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  /**
   * Function to run when the button is clicked.
   */
  onClick: (event?: MouseEvent<HTMLButtonElement>) => void;
}

export interface ButtonFormButtonElementProps
  extends ButtonBaseProps,
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  /**
   * Specify the type and function of the button.
   */
  type: 'button' | 'submit' | 'reset';

  /**
   * If `type='submit'` this is used to link the button to an input field.
   */
  htmlFor?: string;
}

export type ButtonProps =
  | ButtonAnchorElementProps
  | ButtonButtonElementProps
  | ButtonFormButtonElementProps;

interface DefaultState {
  isLoading: boolean;
  loadingLabel?: string;
  disabled: boolean;
  size: ButtonSize;
  variant: ButtonVariant;
  width: ButtonWidth;
  isNotClickable: boolean;
}

const defaultState: DefaultState = {
  isLoading: false,
  disabled: false,
  size: 'medium',
  variant: 'signature',
  width: 'content',
  isNotClickable: false,
};

const resetButtonStyles = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  touch-action: manipulation;
  appearance: none !important;
`;

const notDisabled = ':not([disabled]):not([data-disabled])';
const commonStyles = css`
  ${resetButtonStyles}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: ${radius.s};
  transition: background 0.2s ease, border-color 0.2s ease;
  cursor: pointer;
  width: fit-content;
  box-sizing: border-box;

  :focus,
  :focus-visible {
    outline: solid 1px;
  }

  &[disabled],
  &[data-disabled],
  &:disabled {
    border: none;
    cursor: not-allowed;
    color: ${({ theme }) =>
    theme.typography
      ? theme.typography.disabledText
      : themeLight.typography.disabledText};
    background: ${({ theme }) =>
    theme.componentColors
      ? theme.componentColors.cta.disabled
      : themeLight.componentColors.cta.disabled};

    &:visited {
      color: ${({ theme }) =>
    theme.typography
      ? theme.typography.disabledText
      : themeLight.typography.disabledText};
      background: ${({ theme }) =>
    theme.componentColors
      ? theme.componentColors.cta.disabled
      : themeLight.componentColors.cta.disabled};
    }
  }
`;

function specificVariantStyles(variant: ButtonVariant) {
  switch (variant) {
    case 'signature':
      return css`
        &${notDisabled} {
          background: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.cta.primary
            : themeLight.componentColors.cta.primary};
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandText
            : themeLight.typography.brandText};
        }
        &:visited${notDisabled} {
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandText
            : themeLight.typography.brandText};
        }
        &:hover${notDisabled}, &:focus${notDisabled} {
          background: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.cta.primaryHover
            : themeLight.componentColors.cta.primaryHover};
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandText
            : themeLight.typography.brandText};
        }
      `;
    case 'secondary':
      return css`
        &${notDisabled} {
          background: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.cta.secondary
            : themeLight.componentColors.cta.secondary};
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandText
            : themeLight.typography.brandText};
        }
        &:visited${notDisabled} {
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandText
            : themeLight.typography.brandText};
        }
        &:hover${notDisabled}, &:focus${notDisabled} {
          background: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.cta.secondaryHover
            : themeLight.componentColors.cta.secondaryHover};
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandText
            : themeLight.typography.brandText};
        }
      `;
    case 'outline':
      return css`
        padding: 0 calc(${spacing.space24} - 1px);
        border: 1px solid;
        border-color: ${({ theme }) =>
          theme.border ? theme.border.heavy : themeLight.border.heavy};

        &${notDisabled} {
          background: 'transparent';
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.defaultText
            : themeLight.typography.defaultText};
        }
        &:visited${notDisabled} {
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.defaultText
            : themeLight.typography.defaultText};
        }
        &:hover${notDisabled}, &:focus${notDisabled} {
          border-color: ${({ theme }) =>
          theme.typography
            ? theme.typography.defaultText
            : themeLight.typography.defaultText};
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.defaultText
            : themeLight.typography.defaultText};
        }
      `;
    case 'spruce':
      return css`
        &${notDisabled} {
          background: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.cta.secondaryInverted
            : themeLight.componentColors.cta.secondaryInverted};
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandTextInverted
            : themeLight.typography.brandTextInverted};
        }
        &:visited${notDisabled} {
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandTextInverted
            : themeLight.typography.brandTextInverted};
        }
        &:hover${notDisabled}, &:focus${notDisabled} {
          background: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.cta.secondaryInvertedHover
            : themeLight.componentColors.cta.secondaryInvertedHover};
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandText
            : themeLight.typography.brandText};
        }
      `;
    case 'outline-inverted':
      return css`
        padding: 0 calc(${spacing.space24} - 1px);
        border: 1px solid;
        border-color: ${({ theme }) =>
          theme.border
            ? theme.border.heavyInverted
            : themeLight.border.heavyInverted};

        &${notDisabled} {
          background: 'transparent';
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.defaultTextInverted
            : themeLight.typography.defaultTextInverted};
        }
        &:visited${notDisabled} {
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.defaultTextInverted
            : themeLight.typography.defaultTextInverted};
        }
        &:hover${notDisabled}, &:focus${notDisabled} {
          border-color: ${({ theme }) =>
          theme.typography
            ? theme.border.defaultInverted
            : themeLight.border.defaultInverted};
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.defaultTextInverted
            : themeLight.typography.defaultTextInverted};
        }
      `;
    // this is a temporary solution, waiting for new NAF colors epic
    case 'danger':
      return css`
          &${notDisabled} {
            background: #B02422;
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandTextInverted
            : themeLight.typography.brandTextInverted};
          }

          &:hover${notDisabled}, &:focus${notDisabled} {
            background: #C92A27;
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandTextInverted
            : themeLight.typography.brandTextInverted};
          }

          &:visited${notDisabled} {
            background: #871D1E;
          color: ${({ theme }) =>
          theme.typography
            ? theme.typography.brandTextInverted
            : themeLight.typography.brandTextInverted};
          }
        `;

    default:
      return null;
  }
}

function variantStyles(variant: ButtonVariant, isLoading: boolean) {
  if (isLoading) {
    const commonLoadingStyles = css`
      background: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.cta.disabled
          : themeLight.componentColors.cta.disabled};
      color: ${({ theme }) =>
        theme.typography
          ? theme.typography.disabledText
          : themeLight.typography.disabledText};
    `;

    const loadingStyles = css`
      cursor: default;
      border: none;
      ${commonLoadingStyles}

      &:hover,
      &:focus,
      &:visited {
        ${commonLoadingStyles}
      }
    `;
    return css`
      ${loadingStyles}

      &[disabled],
      &[data-disabled],
      &:disabled {
        ${loadingStyles}
      }
    `;
  }
  const commonVariantStyle = !!variant
    ? css`
        &:hover {
          text-decoration: none;
        }
      `
    : null;
  return [commonVariantStyle, specificVariantStyles(variant)];
}

function sizeStyles(size: ButtonSize) {
  switch (size) {
    case 'small':
      return css`
        height: ${spacing.space40};
        padding: 0 ${spacing.space24};
      `;
    case 'medium':
      return css`
        height: ${spacing.space48};
        padding: 0 ${spacing.space24};
      `;
    case 'large':
      return css`
        height: ${spacing.space56};
        padding: 0 ${spacing.space24};
      `;
    default:
      return null;
  }
}

function widthStyles(width: ButtonWidth) {
  switch (width) {
    case 'half':
      return css`
        width: 50%;
      `;
    case 'full':
      return css`
        width: 100%;
      `;
    default:
      return null;
  }
}

function toggleStyles(props: ButtonBaseProps) {
  const variant: ButtonVariant = props.variant || defaultState.variant;
  const size: ButtonSize = props.size || defaultState.size;
  const width: ButtonWidth = props.width || defaultState.width;
  const isLoading = props.isLoading || defaultState.isLoading;

  return [
    variantStyles(variant, isLoading),
    sizeStyles(size),
    widthStyles(width),
  ];
}

const StyledButton = styled.button<ButtonBaseProps>`
  ${commonStyles}
  ${(props) => toggleStyles(props)}
`;

const StyledAnchor = styled.a<ButtonBaseProps>`
  ${commonStyles}
  ${(props) => toggleStyles(props)}
`;

const StyledPlaceholderDiv = styled.div<ButtonBaseProps>`
  ${commonStyles}
  cursor: default;

  ${(props) => toggleStyles(props)}
`;

const LoadingButton = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: ${spacing.space8};
  }
`;

const StyledText = styled(Text)`
  display: flex;
  align-items: center;

  svg {
    font-size: ${icons.s};
  }
`;

const ButtonContent = ({
  isLoading,
  loadingLabel,
  size,
  children,
}: {
  isLoading: boolean;
  loadingLabel?: string;
  size: ButtonSize;
  children: ReactNode;
}) => {
  const computedVariant =
    size === 'small' ? TextVariant.ButtonSmall : TextVariant.Button;
  return isLoading ? (
    <LoadingButton>
      <Loader variant="neutral" size="small" />
      <Text variant={computedVariant}>{loadingLabel || 'Laster...'}</Text>
    </LoadingButton>
  ) : (
    <StyledText variant={computedVariant}>{children}</StyledText>
  );
};

export const Button: ForwardRefExoticComponent<
  ButtonProps & RefAttributes<HTMLButtonElement | HTMLAnchorElement>
> = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(function (
  {
    isLoading = defaultState.isLoading,
    loadingLabel = defaultState.loadingLabel,
    disabled = defaultState.disabled,
    size = defaultState.size,
    variant = defaultState.variant,
    width = defaultState.width,
    isNotClickable = defaultState.isNotClickable,
    children,
    ...rest
  }: ButtonProps,
  ref,
) {
  const isDisabled = disabled || isLoading;

  if (isNotClickable) {
    return (
      <StyledPlaceholderDiv
        isLoading={isLoading}
        size={size}
        variant={variant}
        width={width}
        disabled={disabled}
      >
        <ButtonContent
          isLoading={isLoading}
          loadingLabel={loadingLabel}
          size={size}
        >
          {children}
        </ButtonContent>
      </StyledPlaceholderDiv>
    );
  }

  if ((rest as ButtonAnchorElementProps).href) {
    const { href, onClick, ...other } = rest as ButtonAnchorElementProps;

    return (
      <StyledAnchor
        ref={ref as RefObject<HTMLAnchorElement>}
        href={!isDisabled ? href : undefined}
        onClick={(e) => {
          if (onClick && !isDisabled) onClick(e);
        }}
        data-disabled={isDisabled ? true : undefined}
        isLoading={isLoading}
        size={size}
        variant={variant}
        width={width}
        {...other}
      >
        <ButtonContent
          isLoading={isLoading}
          loadingLabel={loadingLabel}
          size={size}
        >
          {children}
        </ButtonContent>
      </StyledAnchor>
    );
  }

  if (
    (rest as ButtonButtonElementProps | ButtonFormButtonElementProps).onClick
  ) {
    const { type, onClick, ...other } = rest as
      | ButtonButtonElementProps
      | ButtonFormButtonElementProps;

    return (
      <StyledButton
        ref={ref as RefObject<HTMLButtonElement>}
        type={type as ButtonButtonElementProps['type']}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          if (onClick)
            (onClick as (e: MouseEvent<HTMLButtonElement>) => void)(e);
        }}
        isLoading={isLoading}
        disabled={isDisabled}
        size={size}
        variant={variant}
        width={width}
        {...other}
      >
        <ButtonContent
          isLoading={isLoading}
          loadingLabel={loadingLabel}
          size={size}
        >
          {children}
        </ButtonContent>
      </StyledButton>
    );
  }

  const { type, onClick, ...other } = rest as
    | ButtonButtonElementProps
    | ButtonFormButtonElementProps;
  return (
    <StyledButton
      ref={ref as RefObject<HTMLButtonElement>}
      type={type as ButtonButtonElementProps['type']}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        if (onClick) (onClick as (e: MouseEvent<HTMLButtonElement>) => void)(e);
      }}
      isLoading={isLoading}
      disabled={isDisabled}
      size={size}
      variant={variant}
      width={width}
      {...other}
    >
      <ButtonContent
        isLoading={isLoading}
        loadingLabel={loadingLabel}
        size={size}
      >
        {children}
      </ButtonContent>
    </StyledButton>
  );
});

export default Button;
