import React, { useState, useEffect, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Check } from '@styled-icons/material/Check';
import { Text, TextVariant } from '@naf/text';
import { spacing, fontStyle, themeLight } from '@naf/theme';

export enum LabelVariant {
  OnOff = 'OnOff',
  YesNo = 'YesNo',
  None = 'None',
}

export interface ToggleProps {
  /**
   * Set a value for the input field.
   */
  name: string;

  /**
   * Set the toggle to inactive status.
   */
  disabled?: boolean;

  /**
   * Set toggle to selected / not selected.
   */
  checked?: boolean;

  /**
   * Function when toggle is pressed.
   */
  onChange?: (payload: any) => void;

  /**
   * Show on/off or yes/no labels.
   */
  labelOption?: LabelVariant;

  /**
   * Other props you want to send to `Toggle`.
   */
  [rest: string]: any;

  /**
   * Optional classname.
   */
  className?: string;
}

export const Toggle: ForwardRefExoticComponent<ToggleProps & RefAttributes<HTMLInputElement>> = forwardRef<
  HTMLInputElement,
  ToggleProps
>(({ name, disabled = false, checked = false, onChange, className, labelOption, ...rest }: ToggleProps, ref) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (e: any) => {
    setIsChecked(!isChecked);
    if (onChange) {
      onChange({ name: name, value: e.target.checked });
    }
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const getLabels = () => {
    switch (labelOption) {
      case LabelVariant.OnOff:
        return { off: 'Av', on: 'På' };
      case LabelVariant.YesNo:
        return { off: 'Nei', on: 'Ja' };
      case LabelVariant.None:
        return { off: '', on: '' };
      default:
        return { off: '', on: '' };
    }
  };

  const labels = getLabels();

  return (
    <ToggleWrapper>
      {labelOption && (
        <LabelText variant={TextVariant.Small} color={themeLight.typography.subtleText}>
          {labels.off}
        </LabelText>
      )}
      <ToggleContainer disabled={disabled} className={className} labelOption={labelOption}>
        <ToggleSwitch
          type="checkbox"
          name={name}
          id={name}
          disabled={disabled}
          checked={isChecked}
          onChange={handleChange}
          tabIndex={0}
          ref={ref}
          {...rest}
        />
        <Label htmlFor={name}>
          <Holder>
            <Check size={16} />
          </Holder>
          <Switch />
        </Label>
      </ToggleContainer>
      {labelOption && (
        <LabelText variant={TextVariant.Small} color={themeLight.typography.subtleText}>
          {labels.on}
        </LabelText>
      )}
    </ToggleWrapper>
  );
});

export default Toggle;

const ToggleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const LabelText = styled(Text)`
  line-height: 0;
`;

const ToggleContainer = styled.div<{ disabled: boolean; labelOption?: string }>`
  display: inline-flex;
  position: relative;
  width: ${spacing.space48};

  ${({ labelOption }) =>
    labelOption &&
    css`
      margin: 0 ${spacing.space8};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      ${Label} {
        cursor: not-allowed;
      }
      ${Holder} {
        &:before,
        &:after {
          color: ${({ theme }) =>
            theme.componentColors
              ? theme.componentColors.interactiveElement.disabled
              : themeLight.componentColors.interactiveElement.disabled};
          background-color: ${({ theme }) =>
            theme.componentColors
              ? theme.componentColors.navigation.inactive
              : themeLight.componentColors.navigation.inactive};
        }
      }
      ${Switch} {
        background-color: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.interactiveElement.disabled
            : themeLight.componentColors.interactiveElement.disabled};
      }
    `}
`;

const Label = styled.label`
  display: flex;
  overflow: hidden;
  cursor: pointer;
  border-radius: calc(${spacing.space24} / 2);
  margin: 0;
`;

const Holder = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: -100%;
  transition: margin-left 0.3s ease-in;
  ${fontStyle.bodyText.bodyText};
  &:before,
  &:after {
    display: flex;
    width: 100%;
    height: ${spacing.space24};
    padding: 0;
    box-sizing: border-box;
    color: ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.inputElement.borderActive
        : themeLight.componentColors.inputElement.borderActive};
  }
  &:before {
    content: '';
    padding-left: ${spacing.space48};
    background-color: ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.interactiveElement.active
        : themeLight.componentColors.interactiveElement.active};
  }
  &:after {
    content: '';
    padding-right: ${spacing.space48};
    background-color: ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.interactiveElement.disabled
        : themeLight.componentColors.interactiveElement.disabled};
  }
  svg {
    visibility: hidden;
    position: absolute;
    background-color: transparent;
    margin-left: 4px;
    transition: visibility 0s 0s;
  }
`;

const Switch = styled.span`
  display: flex;
  width: ${spacing.space16};
  margin: ${spacing.space4};
  background-color: ${({ theme }) =>
    theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText};
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${spacing.space24};
  border-radius: 50%;
  transition: all 0.3s ease-in;
`;

const ToggleSwitch = styled.input<any>`
  display: none;
  &:checked + ${Label} {
    ${Holder} {
      margin-left: 0;
      svg {
        visibility: visible;
        transition: visibility 0s 0.3s;
      }
    }
    ${Switch} {
      right: 0;
      background-color: ${({ theme }) =>
        theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText};
    }
  }
`;
