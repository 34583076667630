import React, {
  AnchorHTMLAttributes,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributeAnchorTarget,
  HTMLAttributes,
  RefAttributes,
  RefObject,
} from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, radius, spacing, breakpoints, themeLight } from '@naf/theme';

export enum CardVariant {
  Small = 'small',
  Standard = 'standard',
  Large = 'large',
  Outline = 'outline',
}

export interface CardProps extends HTMLAttributes<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement> {
  /**
   * Set the width of the card. Defaults to `fit-content`.
   */
  width?: number | string;

  /**
   * Set the height of the card. Defaults to `fit-content`.
   */
  height?: string;

  /**
   * Set the size and style of the card.
   */
  variant?: CardVariant;

  /**
   * Set the link of the card.
   */
  href?: string;

  /**
   * Set how the link should open.
   */
  target?: HTMLAttributeAnchorTarget;

  /**
   * Defines if the card should be clickable or not, defaults to false, so that the card _is_ clickable.
   * This will be something other than disabled as this will preserve the styling of the card,
   * but remove the hover effect.
   */
  isNotClickable?: boolean;

  /**
   * Set if the card should be used with a `Link`-component. This will preserve the styling and hover effect, but avoid nesting anchor tags.
   */
  isInternalLink?: boolean;
  linkProps?: Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;
  linkRef?: RefObject<HTMLAnchorElement>;
}

export const BaseCard: ForwardRefExoticComponent<CardProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  CardProps
>(
  (
    {
      isNotClickable = false,
      isInternalLink = false,
      href,
      target,
      variant = CardVariant.Standard,
      children,
      linkProps,
      linkRef,
      ...rest
    }: CardProps,
    ref,
  ) => {
    if (isNotClickable || isInternalLink) {
      return (
        <Container variant={variant} ref={ref} {...rest}>
          <DivWrapper id="card">{children}</DivWrapper>
        </Container>
      );
    }
    return (
      <Container variant={variant} ref={ref} {...rest}>
        <LinkWrapper id="card" href={href} target={target} ref={linkRef} {...linkProps}>
          {children}
        </LinkWrapper>
      </Container>
    );
  },
);

const wrapperStyling = css`
  flex-basis: 100%;
  width: fit-content;
  display: flex;
  text-decoration: none;
  border-bottom: 1px solid;
  margin-bottom: ${spacing.space40};
  border-color: ${({ theme }) => (theme.border ? theme.border.subtle : themeLight.border.subtle)};
`;

const LinkWrapper = styled.a`
  ${wrapperStyling}
`;

const DivWrapper = styled.div`
  ${wrapperStyling}
`;

const Container = styled.div<{ variant: CardVariant; width?: number | string; height?: string }>`
  display: flex;
  width: fit-content;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: fit-content;
  height: ${(props) => props.height};
  ${fontStyle.bodyText.bodyText};
  a,
  a:visited,
  a:hover,
  a:active {
    color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};
  }

  #card {
    padding-bottom: ${spacing.space40};
  }

  /* Small */
  ${({ variant }) =>
    variant === CardVariant.Small &&
    css`
      display: inline-flex;
      width: calc(33% - (${spacing.space32} * (2 / 3)));

      &:not(:nth-child(3n)) {
        margin-right: ${spacing.space32};
      }

      figure {
        height: 4.5rem;
        width: 6rem;
        margin-right: ${spacing.space16};
      }
      #card {
        border: none;
        margin-bottom: 0;
      }
    `}

  /* Standard */
  ${({ variant }) =>
    variant === CardVariant.Standard &&
    css`
      figure {
        height: 7.5rem;
        width: 10rem;
        margin-right: ${spacing.space32};
      }
    `}

  /* Large */
  ${({ variant }) =>
    variant === CardVariant.Large &&
    css`
      figure {
        height: 9rem;
        width: 12rem;
        margin-right: ${spacing.space32};
      }
    `}

  /* Outline */
  ${({ variant }) =>
    variant === CardVariant.Outline &&
    css`
      display: inline-flex;
      width: calc(33% - (${spacing.space32} * (2 / 3)));
      #card {
        padding: ${spacing.space24};
        border: 1px solid;
        border-radius: ${radius.s};
        border-color: ${({ theme }) => (theme.border ? theme.border.subtle : themeLight.border.subtle)};
      }

      @media (max-width: ${breakpoints.s}) {
        #card {
          padding: ${spacing.space16};
          svg {
            right: ${spacing.space16};
            top: 0;
          }
        }
      }
    `}

  @media (max-width: ${breakpoints.s}) {
    display: flex;
    width: 100%;
    figure {
      height: 4.5rem;
      width: 6rem;
      margin-right: ${spacing.space16};
    }
    #card {
      padding-bottom: ${spacing.space32};
      margin-bottom: ${spacing.space32};
    }

    ${({ variant }) =>
      variant === CardVariant.Small &&
      css`
        #card {
          padding-bottom: 0;
        }
      `}
  }
`;
